import React from "react";
import { AnimatePresence } from "framer-motion";
import { Routes, Route, useLocation } from "react-router-dom";
//Pages
import Home from "../../pages/home";
import MachineLearning from "../../pages/machineLearning";
import Hero from "../machineLearning/Hero";
import MLPage from "../../pages/mlPage";

function AnimatedRoutes() {
  const imageDetails = {
    width: 500,
    height: 400,
  };

  const location = useLocation();

  return (
    <AnimatePresence initial={false} mode="wait">
            <Routes key={location} location={location}>
              <Route
                path='/'
                element={<Home imageDetails={imageDetails} />}
              />
              <Route
                path='/model/:id'
                element={<MLPage imageDetails={imageDetails} />}
              />
              {/* <Route
                path='/model/:id'
                element={<Hero/>}
              /> */}
            </Routes>
          </AnimatePresence>
  );
}

export default AnimatedRoutes;
