import React, {useEffect} from 'react'
import Hero from '../components/machineLearning/Hero'
import MachineLearning from './machineLearning'
import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import FeatureSlides from '../components/FeatureSlides/FeatureSlides'

const MLPage = ({ imageDetails }) => {

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
    }, [])

  return (
    <>
      <MachineLearning imageDetails={imageDetails} />
      <Hero />
      {/* <FeatureSlides/>
      <div className='footer'>Build your ideal story today</div> */}
    </>
  );
}

export default MLPage