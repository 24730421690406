import React from 'react'
import HeroCollage from './HeroCollage'
import HeroFooter from './HeroFooter'
import HeroHeader from './HeroHeader'
import HeroPhoneBlock from './HeroPhoneBlock'
import HeroUsedBy from './HeroUsedBy'
import './style.scss'
import HeroBody from './HeroBody'

const Hero = () => {
  return (
    <div className='hero-container'>
        <HeroHeader/>
        <div className='hero-media'>
          <HeroBody/>
        {/* <HeroCollage/>
        <HeroPhoneBlock/> */}
        </div>
        {/* <HeroUsedBy/>
        <HeroFooter/> */}
    </div>
  )
}

export default Hero