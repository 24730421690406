import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
//components
import Header from "./components/header";
//Styles
import "./App.scss";
import AnimatedRoutes from "./components/routes/animatedRoutes";

function App() {

  return (
    <Router>
      <Header />
          <AnimatedRoutes/>
    </Router>
  );
}

export default App;
