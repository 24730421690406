import React, { useEffect, useRef, useState } from 'react'
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import { featureSlides } from '../../data';
import cn from 'classnames';
import FeatureSlide from './FeatureSlide';
import './featureStyle.scss'

function RenderImages({activeFeatureIndex}) {
    return featureSlides.map(({imageUrl}, index)  => 
    ( <img className={cn({"as-primary": activeFeatureIndex === index})}
        style={{backgroundImage: `url(${imageUrl})`}}
        key={imageUrl}
    />)
    );
}

const FeatureSlides = () => {
    const [activeFeatureIndex, setActiveFeatureIndex] = useState(0);
    const featureSliderRef = useRef(null);
    const featureSlidesRightRef = useRef(null);

    useEffect(()=>{
        gsap.registerPlugin(ScrollTrigger);
          function stopTrigger() {
            const tl = gsap.timeline({
              scrollTrigger: {
                trigger: featureSlidesRightRef.current,
                start: "top top",
                end: () => `+=${featureSliderRef.current.offsetHeight}`,
                pin: true,
                scrub: true,
              },
            });
            return tl;
          }
    
        const master = gsap.timeline();
        master.add(stopTrigger());

          
    }, [])

  return (
    <div ref={featureSliderRef} className='feature-slides-container'>
        <div className='feature-slides-left'>
            {featureSlides.map((feature, index) =>(
                <FeatureSlide
                updateActiveImage={setActiveFeatureIndex}
                key={feature.imageUrl}
                title={feature.title}
                description={feature.description}
                index={index}/>
            ))}
        </div>
        <div ref={featureSlidesRightRef} className='feature-slides-right'>
            <RenderImages activeFeatureIndex={activeFeatureIndex}/>
        </div>
    </div>
  )
}

export default FeatureSlides